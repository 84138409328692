// gaAddons FREE v1.0, Copyright 2011, Stephane Hamel - http://gaAddons.com
// Licensed under a Creative Commons Attribution-NonCommercial-NoDerivs 3.0 Unported License
// gaAddons FREE v1.0, Copyright 2011, Stéphane Hamel - http://gaAddons.com
// gaAddons by Stéphane Hamel is licensed under a Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
// This code is provided as is, without guarantee or support.

// Modified by Geonetric for use with VitalSite. Code modified with permission from Stephane Hamel.

$(function() {
	// helper function - allow regex as jQuery selector
	jQuery.expr[':'].regex = function(elem, index, match) {
		var matchParams = match[3].split(','),
			validLabels = /^(data|css):/,
			attr = {
				method: matchParams[0].match(validLabels) ? 
					matchParams[0].split(':')[0] : 'attr',
				property: matchParams.shift().replace(validLabels,'')
			},
			regexFlags = 'ig',
			regex = new RegExp(matchParams.join('').replace(/^\s+|\s+$/g,''), regexFlags);
		return regex.test(jQuery(elem)[attr.method](attr.property));
	};
});

///////////////////
// _trackDownloads
jQuery(document).ready(function($) {
	$(document).on('click', 'a:regex(href,\.(zip|mp\d+|mpe*g|pdf|docx*|pptx*|xlsx*|jpe*g|png|gif|tiff)$)', function() {
		ga('send', 'event', 'download', 'click', this.href.replace(/^.*\/\//, ''), {'nonInteraction': 1});
	});
});

///////////////////
// track mailto:
jQuery(document).ready(function($) {
	$(document).on('click', 'a[href^="mailto:"]', function() {
		ga('send', 'event', 'mailto', 'click', this.href.replace(/^.*\/\//, ''), {'nonInteraction': 1});
	});
});

///////////////////
// _trackOutbound
jQuery(document).ready(function($) {
	$(document).on('click', 'a[href^="http"]:not([href*="//' + location.host + '"])', function() {
		ga('send', 'event', 'outbound', 'click', this.href.match(/\/\/([^\/]+)/)[1]);
	});
});